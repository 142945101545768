import { ContentLayout } from "components/Layout";
import greetingTime from "greeting-time";
import { useEffect, useState } from "react";
import { MdOutlineCategory, MdOutlineFeaturedPlayList } from "react-icons/md";
import { FcSalesPerformance, FcAdvertising } from "react-icons/fc";
import { CgToday, CgTrending } from "react-icons/cg";
import { ImCart } from "react-icons/im";
import { HiOutlineUsers } from "react-icons/hi";

import { Archive, FileText } from "react-feather";

import { useAnalytics } from "../api/getAnalytics";

import { useAuth } from "lib/auth";
import { DashboardLoading } from "components/Elements";
import { Card } from "../components/Card";
import { Authorization, ROLES } from "lib/authorization";
import { Unauthorized } from "features/auth/components/Unauthorized";

export const Dashboard = () => {
  const analyticsQuery = useAnalytics();

  const { user } = useAuth();

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    setGreeting(greetingTime(new Date()));
  }, []);

  if (analyticsQuery.isLoading) {
    return <DashboardLoading />;
  }

  if (!analyticsQuery.data) return null;

  return (
    <Authorization forbiddenFallback={<Unauthorized />} allowedRoles={[ROLES.ADMIN]}>
      <ContentLayout title="Dashboard">
        {/* <Breadcrumbs /> */}
        <div className="">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="lg:col-span-2">
              <div className="mb-2">
                <p className="text-gray-500 text-lg font-bold">
                  {" "}
                  {greeting} 👋🏼 {user.name}
                </p>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-2 mb-2">
                <Card
                  link="./products"
                  icon={<Archive className="h-5 text-gray-600" />}
                  content={analyticsQuery.data.product}
                  label="Products"
                />
                <Card
                  link="./adverts"
                  icon={<FcAdvertising className="text-gray-600" />}
                  content={analyticsQuery.data.adverts}
                  label="Adverts"
                />
                <Card
                  link="./orders"
                  icon={<MdOutlineFeaturedPlayList className="text-gray-600" />}
                  content={analyticsQuery.data.orders}
                  label="Orders"
                />
                <Card
                  link="./users"
                  icon={<HiOutlineUsers className="text-gray-600" />}
                  content={analyticsQuery.data.users}
                  label="Users"
                />
                <Card
                  link="./daily-deals"
                  icon={<CgToday className="text-gray-600" />}
                  content={analyticsQuery.data.dailydeals}
                  label="Daily Deals"
                />
                <Card
                  link="./kinds"
                  icon={<MdOutlineCategory className="text-gray-600" />}
                  content={analyticsQuery.data.kinds}
                  label="Kinds"
                />
                <Card
                  link="./levels"
                  icon={<MdOutlineCategory className="text-gray-600" />}
                  content={analyticsQuery.data.levels}
                  label="Levels"
                />
                <Card
                  link="./banners"
                  icon={<FileText className="text-gray-600" />}
                  content={analyticsQuery.data.banners}
                  label="Banners"
                />
                <Card
                  link="./special-sales"
                  icon={<FcSalesPerformance className="text-gray-600" />}
                  content={analyticsQuery.data.specialsales}
                  label="Special Sales"
                />
                <Card
                  link="./trendings"
                  icon={<CgTrending className="text-gray-600" />}
                  content={analyticsQuery.data.trendings}
                  label="Trendings"
                />
                <Card
                  link="./carts"
                  icon={<ImCart className="text-gray-600" />}
                  content={analyticsQuery.data.carts}
                  label="Carts"
                />
              </div>
            </div>
            <div className="border border-gray-300 shadow-lg rounded-lg p-5">
              <div className="flex flex-row items-center justify-between mb-1">
                <span className="text-xs text-gray-700 uppercase">
                  Total Sales
                </span>
                <span className="text-gray-400 text-xs">This week</span>
              </div>
              <span className="text-gray-400 text-sm">
                There were no sales during this time.
              </span>
              <hr />
              <div className="flex flex-row items-center justify-between mb-1 mt-4">
                <span className="text-xs text-gray-700 uppercase">
                  Total Sales Breakdown
                </span>
                <span className="text-gray-400 text-xs">This week</span>
              </div>
              <span className="text-gray-400 text-sm">
                There were no sales during this time.
              </span>
            </div>
          </div>
        </div>
      </ContentLayout>
    </Authorization>
  );
};
