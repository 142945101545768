import { Spinner } from "components/Elements";
import { MainLayout } from "components/Layout";
import { NotFound } from "features/misc";
import { lazyImport } from "utils/lazyImport";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const { ChangeLogRoutes } = lazyImport(
  () => import("features/changelog"),
  "ChangeLogRoutes"
);
const { IconsRoutes } = lazyImport(
  () => import("features/icons"),
  "IconsRoutes"
);
const { ComingSoon } = lazyImport(
  () => import("features/comingsoon"),
  "ComingSoon"
);
const { AdvertsRoutes } = lazyImport(
  () => import("features/adverts"),
  "AdvertsRoutes"
);
const { OrdersRoutes } = lazyImport(
  () => import("features/orders"),
  "OrdersRoutes"
);
const { ProductsRoutes } = lazyImport(
  () => import("features/products"),
  "ProductsRoutes"
);
const { EvaluationsRoutes } = lazyImport(
  () => import("features/evaluations"),
  "EvaluationsRoutes"
);
const { BidsRoutes } = lazyImport(
  () => import("features/bids"),
  "BidsRoutes"
);
const { CartRoutes } = lazyImport(
  () => import("features/myCart"),
  "CartRoutes"
);
const { CategoriesRoutes } = lazyImport(
  () => import("features/categories"),
  "CategoriesRoutes"
);
const { KindsRoutes } = lazyImport(
  () => import("features/kinds"),
  "KindsRoutes"
);
const { LevelsRoutes } = lazyImport(
  () => import("features/levels"),
  "LevelsRoutes"
);

const { BannersRoutes } = lazyImport(
  () => import("features/banners"),
  "BannersRoutes"
);
const { SpecialSalesRoutes } = lazyImport(
  () => import("features/specialSales"),
  "SpecialSalesRoutes"
);
const { DailyDealsRoutes } = lazyImport(
  () => import("features/dailyDeals"),
  "DailyDealsRoutes"
);
const { TrendingsRoutes } = lazyImport(
  () => import("features/trending"),
  "TrendingsRoutes"
);
const { CartsRoutes } = lazyImport(
  () => import("features/carts"),
  "CartsRoutes"
);
const { Dashboard } = lazyImport(() => import("features/misc"), "Dashboard");
const { Profile } = lazyImport(() => import("features/users"), "Profile");
const { User } = lazyImport(() => import("features/users"), "User");
const { Users } = lazyImport(() => import("features/users"), "Users");

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: "/app",
    element: <App />,
    children: [
      { path: "/app/changes/*", element: <ChangeLogRoutes /> },
      { path: "/app/adverts/*", element: <AdvertsRoutes /> },
      { path: "/app/orders/*", element: <OrdersRoutes /> },
      { path: "/app/products/*", element: <ProductsRoutes /> },
      { path: "/app/evaluations/*", element: <EvaluationsRoutes /> },
      { path: "/app/bids/*", element: <BidsRoutes /> },
      { path: "/app/categories/*", element: <CategoriesRoutes /> },
      { path: "/app/kinds/*", element: <KindsRoutes /> },
      { path: "/app/levels/*", element: <LevelsRoutes /> },
      { path: "/app/banners/*", element: <BannersRoutes /> },
      { path: "/app/special-sales/*", element: <SpecialSalesRoutes /> },
      { path: "/app/daily-deals/*", element: <DailyDealsRoutes /> },
      { path: "/app/trendings/*", element: <TrendingsRoutes /> },
      { path: "/app/carts/*", element: <CartsRoutes /> },
      { path: "/app/cart/*", element: <CartRoutes /> },
      { path: "/app/icons/*", element: <IconsRoutes /> },
      { path: "/app/users", element: <Users /> },
      { path: "/app/users/:userId", element: <User /> },
      { path: "/app/profile", element: <Profile /> },
      { path: "/app/coming-soon", element: <ComingSoon /> },
      { path: "/app/", element: <Dashboard /> },
      { path: "/app/*", element: <NotFound /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
